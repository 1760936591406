 
 .card{
    margin: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 3px #ccc;
    background-color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

 }

 tspan {
  font-size: 12px;
  font-weight: 600;
}
 .card1{
    margin: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 3px #ccc;
    background-color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

}
.card1 img{
  display: block;
  width: 10px;
   background: #E6E7ED;
   border-radius: 50%;
   text-align:center;

}
.card img {
  display: block;
 
   

}

.circleImage1{
  display: inline-block;
      position: relative;
      width: 160px;
      height: 155px;
      overflow: hidden;
      border-radius: 50%;
      background:rgb(42, 64, 71)
}
.circleImage1 img{
      width: 55%;
      margin-left: 23%;
      height: 55%;
      margin-top: 23%;
      align-items:center;
      text-align: center;
}

.circleImage2{
  display: inline-block;
      position: relative;
      width: 160px;
      height: 155px;
      overflow: hidden;
      border-radius: 50%;
      background:rgb(42, 64, 71)
}
.circleImage2 img{
      width: 65%;
      height: 55%;
      margin-top: 20%;
      align-items:center;
      text-align: center;
}

.card img ~ * {
  margin-left: 1rem;
  margin-right: 1rem;
}
.card > :last-child {
  margin-bottom: 0;
}


/* Additional demo display styles */


.card h3 {
  margin-top: 1rem;
  font-size: 1.25rem;
}

.card a{
  text-decoration: none;
   transition: all 0.3s ease-in-out;
  color: black;
}


.card-wrapper {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30ch, 1fr));
  grid-gap: 1.5rem;
  max-width: 100%;
  width: 120ch;
  padding-left: 1rem;
  padding-right: 1rem;
}


.card-body a{
  text-decoration: none;
   margin: 0 1rem;
  transition: all 0.3s ease-in-out;
  color: black;
}

.card-body a:hover{
  color: #ec483b;
}