.partner-header {
  background-color: var(--cretech-black, #12062c);
  position: relative;
}

#card-container {
  width: 200px;
 height: 200px;
 z-index: 1;
 float: left;
}


#partner_card {
 width: 100%;
 height: 100%;
 margin: 10%;
 transform-style: preserve-3d;
 transition: all 0.8s linear;
 box-shadow: 5px 5px 15px rgba(48, 44, 44, 0.3);
 background-color: rgb(64, 62, 75);

}

#card:hover {
 transform: rotateY(180deg);
 box-shadow: -5px 5px 15px rgba(0, 0, 0, 0.3);
}

#card2:hover {
 transform: rotateY(-180deg);
 box-shadow: -5px 5px 15px rgba(0, 0, 0, 0.3);
}

/* #card3:hover {
 transform: rotateX(180deg);
 box-shadow: 5px -5px 15px rgba(0, 0, 0, 0.3);
}
#card3 .back {
 transform: rotateX(-180deg);
} */

.face {
 position: absolute;
 width: 100%;
 height: 100%;
 backface-visibility: hidden;
}

.back {
 display: block;
 transform: rotateY(180deg);
 box-sizing: border-box;
 padding: 10px;
 text-align: center;
 opacity: 0.7;
 background: #f9f3e4;
}

.date {
 color: #d0b2af;
}