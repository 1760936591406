
.flex-r,
.flex-c {
  justify-content: center;
  align-items: center;
  display: flex;
    
}
.flex-c {
  flex-direction: column;
}
.flex-r {
  flex-direction: row;
}

 
.login-text {
  background-color: #1a2625;
  border-radius: 10px;
  padding: 10px 20px;
  width: 400px;
}

 
 
.login-text h1 {
  font-size: 25px;
}
.login-text p {
  font-size: 15px;
  color: #000000b2;
}

form {
  align-items: flex-start !important;
  width: 100%;
 }

.input-box {
  margin: 10px 0px;
  width: 100%;
}
.label {
  font-size: 15px;
  color: rgb(255, 255, 255);
  margin-bottom: 3px;
}
.input {
  background-color: #f6f6f6;
  padding: 0px 5px;
  border: 2px solid rgba(216, 216, 216, 1);
  border-radius: 10px;
  overflow: hidden;
  justify-content: flex-start;
}

input {
  border: none;
  outline: none;
  padding: 10px 5px;
  background-color: #f6f6f6;
  flex: 1;
}
.input i {
  color: rgba(0, 0, 0, 0.4);
}

.check span {
  color: #000000b2;
  font-size: 15px;
  font-weight: bold;
  margin-left: 5px;
}
 
.extra-line {
  font-size: 15px;
  font-weight: 600;
}
.extra-line a {
  color: #0095b6;
}


.bgImg{

background-image:url('/public/assets/images/resources/contact.png'); 
width: 100%;
  height: 700px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
   border: 1px solid red;

}